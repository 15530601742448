* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Font1";
  src: url("../public/fonts/pixel1.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font2";
  src: url("../public/fonts/pixel2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html,
body {
  /* background-image: linear-gradient(90deg, #706e6e, #967751); */
  height: 100%;
}

.App {
  font-family: "Font2";
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0;

  background-color: #000;

  background-image: url("../src/images/logos/Back.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}


.blur {
  background-image: url("../src/images/logos/Back-small.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* Logo */
.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fadein_fadeout {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.fadein_fadeout2 {
  opacity: 0;
  animation: fadeIn2 0.5s ease-in forwards;
}

.spin {
  animation: App-logo-spin 0.5s ease-in forwards;
}

/* Header */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Links */
.App-link {
  color: #61dafb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #4fa3d1;
}

/* Section Styling */
.load-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.did-you-know {
  text-align: center;
  color: #ff9100;
  margin: 20px 0;

  padding: 10px;
  background-color: #282c34a7;
  border: 1px solid #ffb254;
  border-radius: 12px;
}

.BOX {
  display: flex;
  flex-direction: column;
}

.headers {
  color: #b3b3ff;
  background-color: #000;
  display: flex;
  justify-content: left;
  height: 100px;
  /* padding: 10px 15px; */
}

.gform a {
  color: #ffb254;
  font-size: 1.3rem;
  position: absolute;
  text-decoration: none;
  border: 1px solid #ffb254;
  border-radius: 8px;
  padding: 10px;
  top: 1.8rem;
  right: 1.5rem;
}

.gform a:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
  border: 2px solid #ffb254;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7a529;
  padding: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

#nav-links a {
  text-decoration: none;
  color: black;
  font-size: 19px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
}

#nav-links a:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* Logo Styling */
.logo-main {
  height: 90px;
  width: 250px;
  object-fit: contain;
  cursor: default;
}

.fiem {
  height: 40px;
  width: 60px;

  margin-top: 38px;
  margin-right: 110px;
}

/* Button Styling */
.nav-btn {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;

  width: 100%;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: black;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  background: #e7a529;
  transition: all 0.3s ease-in-out;
}

.joinus-btn {
  display: none !important;
}

.nav-btn:hover {
  background: #ff6666;
  box-shadow: 0px 0px 15px rgba(253, 64, 120, 0.8);
  transform: scale(1.1);
}

/* Individual Button Hover Effects */
.home-btn:hover {
  box-shadow: 0px 0px 15px rgba(253, 64, 120, 0.8);
}

.about-btn:hover {
  box-shadow: 0px 0px 15px rgba(130, 64, 253, 0.8);
}

.sponsers-btn:hover {
  box-shadow: 0px 0px 15px rgba(0, 235, 184, 0.8);
}

.team-btn:hover {
  box-shadow: 0px 0px 15px rgba(230, 113, 4, 0.881);
}

.gallery-btn:hover {
  box-shadow: 0px 0px 15px rgba(247, 64, 253, 0.8);
}

.contact-btn:hover {
  box-shadow: 0px 0px 15px rgba(140, 253, 64, 0.8);
}

.joinus-btn:hover {
  box-shadow: 0px 0px 15px rgba(43, 21, 241, 0.8);
}

/* Active Link */
.active {
  background-color: #1d4ed8;
}

/* Main Navigation */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f172a;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.cross {
  position: absolute;
  top: -0.5rem;
  left: 0.25rem;
  color: rgb(49, 0, 0);
  font-size: 35px;
  display: block;
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

nav ul {
  display: flex;
  justify-content: center;
  width: fit-content;
}

nav ul li {
  display: flex;
  justify-content: center;
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  transition: background 0.3s;
}

nav ul li a:not(.active):hover {
  background-color: #172554;
}

/* Menu for Mobile */
nav .menu {
  display: none;
  position: absolute;
  top: 1rem;
  left: 0.8rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 0.2rem;
}

.big-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.footer-container {
  margin-top: auto;
}

.footer {
  /* border-top: 1px solid #e7a529; */
  background-color: black;
  position: relative;
  padding: 2rem;
}

.footer div {
  font-size: 1rem !important;
  color: #e7a529;
}

.web-content-container {
  width: 70%;
  margin: 0 auto;
  overflow: hidden;

  justify-content: center;
}

.web-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: sans-serif;
  font-size: 22px;
  border: 3px solid black;
  border-radius: 7px;
  margin: 20px;
  padding: 10px;
  border: 1px solid #3f80ff;
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 26px rgba(2, 31, 59, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
}

.toggle-member {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;

  width: 30%;
  padding: 20px;
  -webkit-tap-highlight-color: transparent;
}

.toggle-member h5 {
  border: 2px solid rgb(61, 50, 223);
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 26px rgba(2, 31, 59, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
  user-select: none;
}

.toggle-member h5:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  color: rgb(118, 135, 236) !important;
}

.about-container {
  width: 90%;
}

.event-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 45px;
  gap: 30px;
}

.event-content-container {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  border: 1.6px solid rgb(255, 174, 0);
  border-radius: 5px;
  backdrop-filter: blur(3px);
}

.event-box {
  font-size: 25px;
  border: 3px solid black;
  border-radius: 7px;
  margin: 20px;
  padding: 10px;

  border: 1px solid rgb(0, 136, 255);
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 26px rgba(2, 31, 59, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
}

.page-title {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.nav-btn-title {
  text-transform: uppercase;
}

/* Responsive Design */
@media (max-width: 2090px) {
  .did-you-know {
    font-size: 30px !important;
  }

  .event-stat {
    width: 25% !important;
    transform: translateX(150%) !important;
  }

  .about-container {
    width: 90%;
  }

  .nav-btn-title {
    font-size: 1.8rem;
  }

  .nav-icons {
    font-size: 1.3rem;
  }

  .about-txt {
    padding: 30px !important;
  }

  .general {
    font-size: 2.4rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 3rem !important;
  }

  .future-events {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .future-events div {
    font-size: 2.1rem !important;
  }

  .toggle-member {
    width: 25% !important;
  }

  .toggle-member h5 {
    font-size: 2.4rem !important;
  }

  .team {
    width: 100% !important;
    padding: 80px !important;
    gap: 110px !important;
  }

  .team-member {
    scale: 1.2;
    margin: 0 auto;
    width: fit-content;
  }

  .team-member:hover {
    transform: scale(1.05) !important;
    transition: transform 0.2s ease-in-out !important;
  }
}

@media (max-width: 1820px) {
  .nav-btn-title {
    font-size: 1.8rem;
  }

  .nav-icons {
    font-size: 1.3rem;
  }

  .event-stat {
    width: 30% !important;
    transform: translateX(120%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 30px !important;
  }

  .general {
    font-size: 2rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.8rem !important;
  }

  .future-events {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .future-events div {
    font-size: 1.9rem !important;
  }

  .toggle-box {
    padding: 10px !important;
  }

  .toggle-member {
    width: 25% !important;
  }

  .toggle-member h5 {
    font-size: 2.2rem !important;
  }

  .team {
    width: 100% !important;
  }

  .team-member {
    scale: 1.2;
    margin: 0 auto;
    width: fit-content;
  }
}

@media (max-width: 1665px) {
  .event-stat {
    width: 30% !important;
    transform: translateX(120%) !important;
  }

  .about-container {
    width: 90%;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .about-txt {
    padding: 30px !important;
  }

  .nav-btn-title {
    font-size: 1.5rem;
  }

  .nav-icons {
    font-size: 1.2rem;
  }

  .general {
    font-size: 1.7rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .future-events div {
    font-size: 1.5rem !important;
  }

  .toggle-box {
    padding: 10px !important;
  }

  .toggle-member {
    width: 28% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    gap: 30px !important;
    padding: 30px !important;
  }

  .team-member {
    scale: 1;
    margin: 0 auto;
    width: fit-content;
  }
}

@media (max-width: 1465px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .navbar a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .nav-btn-title {
    font-size: 1.5rem;
  }

  .nav-icons {
    font-size: 1.3rem;
  }

  #nav-links a {
    font-size: 12px;
  }

  .loadingscreen {
    width: 40px;
    height: 40px;
  }

  h1 {
    font-size: 30px;
  }

  .did-you-know {
    font-size: 16px !important;
  }

  .web-content-container {
    width: 75%;
  }

  .web-content {
    font-size: 18px;
  }

  .event-stat {
    width: 40% !important;
    transform: translateX(75%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 20px !important;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .general {
    font-size: 1.6rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .toggle-member {
    width: 29% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    gap: 30px !important;
  }

  .team-member {
    margin: 0 auto;
    width: fit-content;
  }

  .socials {
    width: 100%;
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 2rem !important;
  }

  .error-container marquee span {
    font-size: 2.5rem !important;
  }

  .return-home {
    scale: 1;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.8rem !important;
  }

  .para {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 1380px) {
  .navbar {
    padding: 15px;
  }

  .nav-btn-title {
    font-size: 1.5rem;
  }

  .nav-icons {
    font-size: 1.2rem;
  }

  h1 {
    font-size: 30px;
  }

  .event-stat {
    width: 40% !important;
    transform: translateX(75%) !important;
  }

  .about-container {
    width: 90%;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .about-txt {
    padding: 20px !important;
  }

  .general {
    font-size: 1.6rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .toggle-member {
    width: 32% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 50px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    width: fit-content;
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 2rem !important;
  }

  .error-container marquee span {
    font-size: 2.5rem !important;
  }

  .return-home {
    scale: 1;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.8rem !important;
  }

  .para {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 1281px) {
  .team {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .team-member {
    width: 300px !important;
  }
}

@media (max-width: 1117px) {
  .navbar {
    padding: 12px;
  }

  .nav-btn-title {
    font-size: 1.5rem;
  }

  .nav-icons {
    font-size: 1.2rem;
  }

  .event-stat {
    width: 60% !important;
    transform: translateX(35%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 20px !important;
  }

  .general {
    font-size: 1.6rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .toggle-member {
    width: 38% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    transform: translateY(-1px) !important;

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 50px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    scale: 1;
    margin: 0 auto;
    width: 280px !important;
    transform: translateX(5px);
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.8rem !important;
  }

  .error-container marquee span {
    font-size: 2.4rem !important;
  }

  .return-home {
    scale: 0.95;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.8rem !important;
  }

  .para {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 984px) {
  .nav-btn-title {
    font-size: 1.5rem;
  }

  .nav-icons {
    font-size: 0.9rem;
  }

  .event-stat {
    width: 60% !important;
    transform: translateX(35%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 20px !important;
  }

  .general {
    font-size: 1.6rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .footer {
    padding: 2rem;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .toggle-member {
    width: 44% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    transform: translateY(-1px) !important;

    padding: 20px !important;

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 30px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    scale: 0.95;
    margin: 0 auto;
    width: fit-content;
    transform: translateX(5px);
  }

  .contact-container {
    width: 65% !important;
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.7rem !important;
  }

  .error-container marquee span {
    font-size: 2.3rem !important;
  }

  .return-home {
    scale: 0.94;
  }
}

@media (max-width: 925px) {
  .event-stat {
    width: 60% !important;
    transform: translateX(40%) !important;
  }

  .toggle-member {
    width: 44% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    transform: translateY(-1px) !important;

    padding: 20px !important;

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 0px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    scale: 0.9 !important;

    margin: 0 auto;
    width: fit-content;
    transform: translateX(-5px);
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.7rem !important;
  }

  .error-container marquee span {
    font-size: 2.3rem !important;
  }

  .return-home {
    scale: 0.94;
  }
}

@media (max-width: 902px) {
  .event-stat {
    width: 50% !important;
    transform: translateX(50%) !important;
  }

  .toggle-member {
    width: 44% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    transform: translateY(-1px) !important;
    padding: 20px !important;

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 0px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    scale: 0.9;

    margin: 0 auto;
    width: 260px !important;
    transform: translateX(-5px);
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.7rem !important;
  }

  .error-container marquee span {
    font-size: 2.3rem !important;
  }

  .return-home {
    scale: 0.94;
  }
}

@media (max-width: 856px) {
  .nav ul {
    width: 70% !important;
  }

  .nav-btn-title {
    font-size: 1.2rem !important;
  }

  .nav-icons {
    font-size: 1rem !important;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 50% !important;
    transform: translateX(50%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 18px !important;
  }

  .general {
    font-size: 1.7rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .future-events div {
    font-size: 1.5rem !important;
  }

  .footer {
    padding: 2rem;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .toggle-member {
    width: 48% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    transform: translateY(-1px) !important;
    padding: 0px !important;

    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 0px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    width: 258px !important;
    transform: translateX(15px) !important;
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.7rem !important;
  }

  .error-container marquee span {
    font-size: 2.3rem !important;
  }

  .return-home {
    scale: 0.94;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.8rem !important;
  }

  .para {
    font-size: 1.9rem !important;
  }
}

@media (max-width: 820px) {
  .team-member {
    width: 250px !important;
  }
}

@media (max-width: 790px) {
  .team-member {
    width: 235px !important;
  }
}

@media (max-width: 768px) {
  .headers {
    justify-content: center;
  }

  .logo-main {
    height: 100px !important;
    width: 250px;
    margin-left: 65px !important;
  }

  .fiem {
    margin-right: 30px !important;
    transform: translate(5px, 2px) !important;
  }

  .hamburger {
    transform: translate(670px, -76px) !important;
  }

  .cross {
    transform: translate(675px, -69px) !important;
  }

  .navbar {
    padding: 0px !important;
  }

  .nav-btn-title {
    font-size: 2rem !important;
  }

  .gform a {
    display: none;
  }

  nav .menu {
    display: flex;
    opacity: 0;
  }

  nav ul.open span {
    display: none;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
  }

  #nav-links a {
    font-size: 20px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul.open span {
    display: none;
  }

  #nav-links a {
    font-size: 20px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 50%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
    padding: 15px;

    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }

  nav ul li {
    display: flex;
    width: 100%;
    text-align: center;
    color: yellow;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
    padding-left: 20%;
    padding-right: 20%;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 55% !important;
    transform: translateX(45%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 18px !important;
  }

  .general {
    font-size: 1.8rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .future-events div {
    font-size: 1.5rem !important;
  }

  .footer {
    padding: 2.2rem;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .toggle-member {
    width: 54% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    scale: 0.99;
    transform: translateY(-6px) !important;

    padding: 50px !important;

    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 50px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    scale: 1 !important;

    margin: 0 auto;
    width: 312px !important;
  }

  .error-container {
    scale: 0.85 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.7rem !important;
  }

  .error-container marquee span {
    font-size: 2.3rem !important;
  }

  .return-home {
    scale: 0.92;
  }
}

@media (max-width: 753px) {
  .toggle-member {
    width: 60% !important;
  }

  .team-member {
    width: 280px !important;
  }

  .hamburger {
    transform: translate(655px, -76px) !important;
  }

  .cross {
    transform: translate(660px, -69px) !important;
  }
}

@media (max-width: 720px) {
  .hamburger {
    transform: translate(625px, -76px) !important;
  }

  .cross {
    transform: translate(630px, -69px) !important;
  }
}

/* Mobile Navigation */
@media (max-width: 690px) {
  .headers {
    justify-content: center;
  }

  .logo-main {
    height: 100px !important;
    width: 250px;
    margin-left: 50px !important;
  }

  .fiem {
    margin-right: 30px !important;
    transform: translate(5px, 2px) !important;
  }

  .hamburger {
    transform: translate(540px, -76px) !important;
  }

  .cross {
    transform: translate(545px, -69px) !important;
  }

  .nav-btn-title {
    font-size: 2rem !important;
  }

  .headers {
    height: 100px;
  }

  .gform a {
    display: none;
  }

  .event-content-container {
    width: 100%;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 55% !important;
    transform: translateX(45%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 18px !important;
  }

  .general {
    font-size: 1.5rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .footer {
    padding: 2rem;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .toggle-member {
    width: 65% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100%;
    scale: 1;
    transform: translateY(-1px) !important;

    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 0px !important;
  }

  .team-member {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    width: 300px !important;
    scale: 0.85 !important;
    transform: translateX(5px);
  }

  .web-content {
    font-size: 18px;
  }

  .navbar {
    padding: 0 !important;
  }

  .nav-btn {
    justify-content: center;
  }

  .joinus-btn {
    display: flex !important;
  }

  nav .menu {
    display: flex;
    opacity: 0;
  }

  nav ul.open span {
    display: none;
  }

  .nav-btn {
    display: flex;
  }

  #nav-links a {
    font-size: 20px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul.open span {
    display: none;
  }

  #nav-links a {
    font-size: 20px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 76%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
    padding: 15px;

    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }

  nav ul li {
    display: flex;
    width: 100%;
    text-align: center;
    color: yellow;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
    padding-left: 20%;
    padding-right: 20%;
  }

  .contact-container {
    width: 100% !important;
  }

  .error-container {
    scale: 0.8 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.5rem !important;
  }

  .error-container marquee span {
    font-size: 2.2rem !important;
  }

  .return-home {
    scale: 0.9;
  }
}

@media (max-width: 624px) {
  .hamburger {
    transform: translate(530px, -76px) !important;
  }

  .cross {
    transform: translate(535px, -69px) !important;
  }d

  .toggle-member {
    width: 70% !important;
  }

  .team-member {
    width: 260px !important;
  }
}

@media (max-width: 580px) {
  .App {
    background-image: url("../src/images/logos/back_mobile.png") !important;
  }

  .blur {
    background-image: url("../src/images/logos/back_mobile_small.png") !important;
  }

  .headers {
    justify-content: center;
  }

  .logo-main {
    height: 100px !important;
    width: 250px;
    margin-left: 50px !important;
  }

  .fiem {
    margin-right: 30px !important;
    transform: translate(5px, 2px) !important;
  }

  .hamburger {
    transform: translate(450px, -78px) !important;
  }

  .cross {
    transform: translate(455px, -71px) !important;
  }

  .nav-btn-title {
    font-size: 1.8rem !important;
  }

  .event-content-container {
    width: 100%;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 100% !important;
    transform: translateX(0%) !important;
  }

  .about-container {
    width: 90%;
  }

  .about-txt {
    padding: 15px !important;
  }

  .general {
    font-size: 1.7rem !important;
    padding: 20px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .future-events div {
    font-size: 1.75rem !important;
  }

  .footer {
    padding: 1.8rem;
  }

  .footer div {
    font-size: 1.3rem !important;
  }

  .toggle-member {
    width: 75% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;

    padding: 0px !important;

    transform: translateY(-1px) !important;
    gap: 0px !important;
  }

  .team-member {
    margin: 0 auto;
    width: 262px !important;

    scale: 0.85 !important;
    transform: translateX(10px);
  }

  .error-container {
    scale: 0.8 !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.5rem !important;
  }

  .error-container marquee span {
    font-size: 2.2rem !important;
  }

  .return-home {
    scale: 0.9;
  }
}

@media (max-width: 518px) {
  .team-member {
    margin: 0 auto;
    width: 250px !important;

    scale: 0.8 !important;
    transform: translateX(10px);
  }

  .hamburger {
    transform: translate(430px, -77px) !important;
  }

  .cross {
    transform: translate(435px, -70px) !important;
  }
}

@media (max-width: 511px) {
  .hamburger {
    transform: translate(420px, -77px) !important;
  }

  .cross {
    transform: translate(425px, -70px) !important;
  }
}

@media (max-width: 480px) {
  .App {
    background-image: url("../src/images/logos/back_mobile.png") !important;
  }

  .blur {
    background-image: url("../src/images/logos/back_mobile_small.png") !important;
  }

  .headers {
    justify-content: center;
  }

  .logo-main {
    height: 100px !important;
    width: 250px;
    margin-left: 30px !important;
  }

  .fiem {
    margin-right: 110px !important;
    transform: translate(5px, 1px) !important;
  }

  .hamburger {
    transform: translate(380px, -77px) !important;
  }

  .cross {
    transform: translate(385px, -70px) !important;
  }

  .nav-btn-title {
    font-size: 1.7rem !important;
  }

  .headers {
    height: 100px;
  }

  .web-content-container {
    width: 100%;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 100% !important;
    transform: translateX(0%) !important;
  }

  .about-txt {
    padding: 18px !important;
  }

  .general {
    font-size: 1.8rem !important;
    padding: 2px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .future-events div {
    font-size: 1.7rem !important;
  }

  .footer {
    padding: 2rem;
  }

  .footer div {
    font-size: 1.2rem !important;
  }

  .toggle-member {
    width: 100% !important;
  }

  .toggle-member h5 {
    font-size: 2rem !important;
  }

  .team {
    width: 100% !important;
    display: grid;
    scale: 1;
    grid-template-columns: repeat(1, 1fr) !important;

    padding: 30px !important;

    transform: translateY(-1px) !important;
    gap: 50px !important;
  }

  .team-member {
    scale: 1 !important;
    margin: 0 auto;
    width: 400px !important;
    transform: translateX(8px) !important;
  }

  .team-member:hover {
    transform: scale(1) !important;
    transition: transform 0.2s ease-in-out;
  }

  .socials {
    width: 50%;
  }

  .web-content {
    font-size: 20px;
  }

  .gform a {
    display: none;
  }

  .navbar {
    padding: 0 !important;
  }

  nav .menu {
    display: flex;
  }

  nav ul.open span {
    display: none;
  }

  .nav-btn {
    display: flex;
  }

  #nav-links a {
    font-size: 20px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 76%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
    padding: 15px;

    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
  }

  nav ul li {
    display: flex;
    width: 100%;
    text-align: center;
    color: yellow;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
    padding-left: 20%;
    padding-right: 20%;
  }

  .contact-container {
    width: 100% !important;
  }

  .contact-item a {
    font-size: 1.5rem;
  }

  .contact-icon {
    font-size: 1.4rem !important;
    color: #4e0e0e;
  }

  .error-container {
    scale: 0.8 !important;
  }

  .error-container h3 {
    font-size: 2rem !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1.1rem !important;
  }

  .error-container marquee span {
    font-size: 2.2rem !important;
  }

  .return-home {
    scale: 0.9;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.7rem !important;
  }

  .para {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 432px) {
  .team-member {
    width: 350px !important;
  }

  .hamburger {
    transform: translate(350px, -77px) !important;
  }

  .cross {
    transform: translate(355px, -70px) !important;
  }
}

@media (max-width: 420px) {
  .hamburger {
    transform: translate(335px, -77px) !important;
  }

  .cross {
    transform: translate(340px, -70px) !important;
  }
}

@media (max-width: 391px) {
  .App {
    background-image: url("../src/images/logos/back_mobile.png") !important;
  }

  .blur {
    background-image: url("../src/images/logos/back_mobile_small.png") !important;
  }

  .headers {
    justify-content: center;
  }

  .logo-main {
    height: 100px !important;
    width: 210px;
    margin-left: 30px !important;
  }

  .fiem {
    transform: translate(5px, -1px) !important;
  }

  .hamburger {
    transform: translate(310px, -78px) !important;
  }

  .cross {
    transform: translate(315px, -71px) !important;
  }

  .event-container {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-stat {
    width: 100% !important;
    transform: translateX(0%) !important;
  }

  .about-txt {
    padding: 15px !important;
  }

  .general {
    font-size: 1.5rem !important;
    padding: 0px !important;
  }

  .expect {
    font-size: 2.5rem !important;
  }

  .future-events {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .future-events div {
    font-size: 1.4rem !important;
  }

  .footer {
    padding: 1.8rem;
  }

  .footer div {
    font-size: 1rem !important;
  }

  .toggle-member {
    width: 100% !important;
  }

  .toggle-member h5 {
    font-size: 1.9rem !important;
  }

  .nav-btn-title {
    font-size: 1.4rem !important;
  }

  .team {
    width: 100% !important;
    transform: translateY(-1px) !important;
    gap: 50px !important;
  }

  .team-member {
    margin: 0 auto;
    width: 300px !important;
    transform: translateX(8px) !important;
  }

  .socials {
    width: 50%;
  }

  .contact-item a {
    font-size: 1.2rem;
  }

  .contact-icon {
    font-size: 1.2rem !important;
    color: #4e0e0e;
  }

  .error-container {
    scale: 0.7 !important;
  }

  .error-container h3 {
    font-size: 2rem !important;
  }

  .error-container h1,
  h4,
  marquee span {
    font-size: 1rem !important;
  }

  .error-container marquee span {
    font-size: 2rem !important;
  }
  .return-home {
    scale: 0.8;
  }

  .volunteer-btn a {
    padding: 15px !important;
    font-size: 1.5rem !important;
  }

  .para {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 360px) {
  .logo-main {
    height: 100px !important;
    width: 220px;
    margin-left: 50px !important;
  }

  .fiem {
    transform: translate(5px, -1px) !important;
  }

  .hamburger {
    transform: translate(290px, -77px) !important;
  }

  .cross {
    transform: translate(295px, -70px) !important;
  }

  .toggle-member {
    width: 100% !important;
  }

  .toggle-member h5 {
    font-size: 1.7rem !important;
  }
}

/* profile pics */
.team {
  padding: 50px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.team-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.team-member {
  text-transform: uppercase;
  font-size: 1.4rem;
  border: 2px solid rgb(0, 145, 255);
  border-radius: 6px;
  text-align: center;
  margin-right: 20px;
  width: 260px;
  flex-shrink: 0;
  padding: 20px;
  color: rgb(255, 255, 255);
  box-shadow: 0 0 26px rgba(2, 34, 64, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
  background-color: #282c34a7;
}

.team-member:hover {
  border: 2px solid rgb(0, 145, 255);
  box-shadow: 0px 4px 20px rgb(0, 145, 255);
}

.team-member a {
  color: rgb(56, 156, 195);
  text-decoration: none;
}

.Job-Role {
  text-transform: capitalize;
  font-family: "Font2";
  font-size: 1.3rem;
  color: rgb(255, 162, 0);
}

.socials {
  height: 40px;
  display: flex;
  justify-content: space-evenly;
}

.socials .team-socials {
  transform: translateY(15px);
  font-size: 25px;
  color: rgb(208, 206, 206);
}

.socials .team-socials:hover {
  font-size: 30px;
  transition: all 0.1s ease-in-out;
}

.profile-pic {
  height: 200px;
  width: 200px;
  border: 2px solid rgb(123, 0, 255);
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 0 26px rgba(2, 34, 64, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* contacts */
.contact-container {
  width: 50%;
  text-align: center;
  padding: 20px;
}

.contact-content-container {
  border: 1.6px solid #e7a529;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
  font-family: "Font2";
}

.contact-item:hover {
  transform: scale(1.05);
}

.contact-icon {
  font-size: 1.9rem;
  color: #ffffff;
  margin-right: 10px;
}

.contact-link {
  text-decoration: none;
  font-size: 2rem;
  color: #ffffff;
  font-weight: 500;
}

.contact-link:hover {
  color: #ffd358;
}

.about-txt {
  font-family: "Font2";
  font-size: 1.5rem;
  color: white;
  padding: 30px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  border: 1.6px solid #e7a529;
  background-color: #1c1e20a7;
}

.general {
  font-size: 1.7rem;
  padding: 10px;
  text-align: center;
}

.future-events {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.future-events div {
  background-color: #1c1e20a7;
  box-shadow: 0px 1px 6px rgb(0, 136, 255);

  padding: 10px;
  border: 1px solid rgb(0, 136, 255);
  border-radius: 9px;
  text-align: center;
}

.future-events div:hover {
  transform: scale(1.03);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 20px rgb(0, 136, 255);
}

.linkedin:hover {
  color: #3b5fff;
}

.github:hover {
  color: white;
}

.error-content-container {
  height: fit-content;
}

.error-container {
  gap: 80px;

  scale: 0.8;
  width: 95%;
  text-align: center;
  padding: 20px;
  -webkit-tap-highlight-color: transparent;

  border: 1.8px solid #e7a529;
}

.error-container h3 {
  font-size: 3rem;
}

.error-container h4 {
  font-size: 2.2rem;
}

.return-home h1:hover {
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
  border: 3px solid #e7a529;
}
